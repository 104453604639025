<template>
    <div class="scan-delivery-result-container">
        <template v-if="!showError">
            <div class="order-sn-container" >
                <div class="order-sn">配送单号：{{order_sn}}</div>
                <div class="delivery">配送员：{{delivery.name}}，电话：{{delivery.mobile}}</div>
            </div>
            <template v-if="goodsList.length > 0">
                <div class="goods-list">
                    <div class="goods-info" v-for="(goods,goodsIndex) in showGoodsList">
                        <div class="goods-img">
                            <img :src="goods.path"/>
                        </div>
                        <div class="info">
                            <div class="goods-title">
                                {{goods.title}}
                            </div>
                            <div class="goods-number">
                                <span>{{goods.spec_text ? goods.spec_text : '默认规格'}}</span>× {{goods.goods_number}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttons" v-if="!showResult">
                    <button class="btn" @click="showConfirm">
                        确认到货
                    </button>
                </div>
            </template>

            <template v-if="goodsList.length == 0">
                <div class="warning">收货人：{{worker.real_name}}<br>操作时间：{{worker.receive_time}}<br>该送货单内商品已全部收货</div>
            </template>
        </template>


        <nut-popup v-model="show" round :closeable="closeable" position="bottom" :style="{ height: '40%' }"
                   :close-on-click-overlay="false">
            <div class="confirm-window">
                <div class="title">确认到货提示</div>
                <template v-if="!showResult">
                    <div class="content">
                        请再次确认页面中展示的商品已全部到货，当您点击确认到货按钮后，系统将更新这些商品的到货状态！
                    </div>
                    <div class="buttons">
                        <button class="btn" @click="confirmGetGoods">
                            确认到货
                        </button>
                    </div>
                </template>
                <div class="result" v-if="showResult">
                    <nut-icon type="tick" style="margin-right: 10px;"></nut-icon>
                    到货状态更新成功
                </div>
            </div>
        </nut-popup>

        <nut-popup v-model="showError" :closeable="closeable" position="center"
                   :style="{ height: '200px',width:'80%',borderRadius:'5px' }"
                   :close-on-click-overlay="false" @close="gotHome">
            <div class="errors">
                <div class="error-message">{{error}}</div>
                <div class="error-close-btn" @click="gotHome">点击关闭</div>
            </div>
        </nut-popup>
    </div>
</template>

<script>
  import CellV3 from '../../template/community/cell/cellV3'
  import GoodsV4 from '../../template/community/goods/GoodsV4'
  import { hideLoading, showLoading } from '@uni/loading'

  export default {
    name: 'scan-delivery-result-batch',
    components: {GoodsV4, CellV3},
    data () {
      return {
        sn: '',
        goodsList: [],
        showGoodsList:[],
        order_sn: '',
        delivery: {},
        show: false,
        closeable: true,
        showResult: false,
        showError: false,
        error: '',
        worker:{}
      }
    },
    created () {
      document.title = '确认到货'
      console.log(this.$route.query)
      this.sn = this.$route?.query?.code || ''
      this.getOrderInfo()
    },
    methods: {
      gotHome () {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({type: 'navigateBack'})
        } else {
          this.$router.push({path: 'self-extract-home'})
        }
      },
      async getOrderInfo () {
        this.$api.community.points.getSendReceiveInfo({sn: this.sn}).then(response => {
          this.order_sn = response.data.sn
          this.goodsList = response.data.goodsList
          this.delivery = response.data.delivery
          this.worker = response.data.worker
          this.showGoodsList = response.data.showGoodsList
        }).catch(error => {
          this.showError = true
          this.error = error.message
        })
      },
      showConfirm () {
        this.show = true
      },
      confirmGetGoods () {

        let params = {
          sn: this.order_sn,
          goods: this.goodsList.map(item => {
            return {
              goods_id: item.goods_id,
              spec_ids: item.spec_ids
            }
          })
        }

        showLoading({
          content: '到货状态更新中',
        })
        this.closeable = false

        console.log(JSON.stringify(params))
        this.$api.community.points.confirmReceiveGoods(params).then(response => {
          setTimeout(() => {
            this.showResult = true
            this.closeable = true
            hideLoading()
          }, 300)
        }).catch(error => {
          this.$toast.fail(error.message)
        })

      }
    },
  }
</script>

<style scoped lang="scss">
    .scan-delivery-result-container {
        padding: 10px;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 20px);

        .order-sn-container {
            display: flex;
            flex-direction: column;
            border: #ccc dashed 1px;
            padding: 10px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background: #FFF9EC;
            color: #333;

            .order-sn {
                font-size: 16px;
            }

            .delivery {
                font-size: 14px;
                color: #999;
                margin-top: 10px;
            }
        }

        .goods-list {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .goods-info {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                background: #fff;
                padding: 10px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;

                .goods-img {
                    width: 70px;
                    height: 70px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    overflow: hidden;
                    margin-right: 10px;

                    img {
                        width: 70px;
                        height: 70px;
                    }
                }

                .info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .goods-title {
                        font-size: 16px;
                        margin-top: 10px;
                    }

                    .goods-specs {
                        font-size: 14px;
                        color: #ccc;
                        margin-top: 6px;
                    }

                    .goods-number {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 20px;
                        color: red;
                        font-weight: bold;
                        margin-top: 15px;

                        span {
                            font-size: 14px;
                            margin-right: 20px;
                            font-weight: 300;
                            color: #333;
                        }
                    }
                }

            }
        }

        .buttons {
            height: 60px;
            padding: 10px 50px;
            display: flex;

            .btn {
                flex: 1;
                bottom: calc(30px + env(safe-area-inset-bottom));
                bottom: calc(30px + constant(safe-area-inset-bottom));
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
                background-clip: padding-box;
                border: 4px solid rgba(244, 106, 23, 0.15);
                border-radius: 29px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }

        .warning {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #999;
            height: 300px;
            text-align: center;
            line-height: 25px;
        }
    }

    .confirm-window {
        display: flex;
        flex-direction: column;

        .title {
            flex: 1;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0, 0, 0, 0.6);
            padding: 15px 0;
        }

        .content {
            font-size: 16px;
            color: #333;
            padding: 30px 50px;
            line-height: 25px;
        }

        .result {
            height: 200px;
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: #333;
            align-items: center;
        }
    }

    .errors {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 150px;

        .error-message {
            width: 100%;
            height: 100px;
            font-size: 16px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .error-close-btn {
            flex: 1;
            display: flex;
            font-size: 14px;
            color: #999;
            align-items: flex-end;
            justify-content: center
        }
    }


</style>
